<template>
  <div>
    <!-- Page Header -->
    <section class="bg-dark pt-5 pb-4">
      <div class="container py-5">
        <h1 class="text-light pt-1 pt-md-3 mb-4">My Favourite Lawyers</h1>
      </div>
    </section>
    <!-- / Page Header -->

    <!-- Results -->
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
      <div class="container pt-4 pb-5 mb-md-4">
        <!-- Breadcrumb-->
        <breadcrumb :crumbs="crumbs" class="pb-4 my-2"></breadcrumb>

        <!-- Results grid -->
        <div class="row">
          <div class="col-md-3">
            <favourite-filters :favourites="favourites"
                               :local-filters="localFilters"></favourite-filters>
          </div>
          <div class="col-md-9">
            <!-- Is Loading -->
            <div v-if="isLoading" class="text-center">
              <spinner></spinner>
            </div>
            <!-- / Is Loading -->

            <!-- No results -->
            <no-results v-if="!isLoading && favouritesToShow.length === 0">
              Set your favourite lawyers using the
              <router-link to="/advisors/find-a-lawyer">Find a Lawyer Wizard</router-link>
            </no-results>
            <!-- / No results -->

            <!-- Favourites -->
            <favourite-card v-for="(favourite, i) in favouritesToShow"
                            :key="i"
                            :favourite="favourite"
                            @view-more-details="viewMoreDetailsFor(favourite)"
                            class="mb-3"></favourite-card>
            <!-- / Favourites -->

            <favourite-more-details-modal @close="viewingMoreDetailsFor = null"
                                          @start-recommending="startRecommending"
                                          :favourite="viewingMoreDetailsFor"
            ></favourite-more-details-modal>

            <recommend-lawyer-modal @close="recommending = null"
                                    :favourite="recommending"
            ></recommend-lawyer-modal>

          </div>
        </div>
        <!-- / Results grid -->
      </div>
    </section>
    <!-- / Results -->
  </div>
</template>

<script>
import Breadcrumb from "../components/Breadcrumb";
import Spinner from "../components/Spinner";
import NoResults from "../components/NoResults";
import Forms from "../mixins/Forms";
import FavouriteCard from "./Favourites/FavouriteCard";
import FavouriteMoreDetailsModal from "./Favourites/FavouriteMoreDetailsModal";
import RecommendLawyerModal from "./Favourites/RecommendLawyerModal";
import FavouriteFilters from "./Favourites/FavouriteFilters";
import {mapGetters} from 'vuex';

export default {
  name: "Favourites",

  components: {
    FavouriteFilters,
    RecommendLawyerModal, FavouriteMoreDetailsModal, Breadcrumb, Spinner, NoResults, FavouriteCard
  },
  mixins: [Forms],

  data() {
    return {
      crumbs: [
        {
          href: "/",
          label: "Home",
        },
        {
          href: "#",
          label: "My Favourite Lawyers",
          active: true,
        },
      ],
      filters: {},
      localFilters: {},
      viewingMoreDetailsFor: null,
      recommending: null
    }
  },

  created() {},

  methods: {
    viewMoreDetailsFor(lawyer) {
      this.viewingMoreDetailsFor = lawyer;
    },

    startRecommending() {
      this.recommending = this.viewingMoreDetailsFor;
      this.viewingMoreDetailsFor = null;
    }
  },

  computed: {
    ...mapGetters('favourites', {
      favourites: 'favourites'
    }),

    favouritesToShow() {
      if (this.localFilters.speciality_id && this.localFilters.speciality_id !== null) {
        return _.filter(this.favourites, favourite => {
          let specialityIds = _.map(favourite.bookmarked_user_role.specialities, 'id');
          return specialityIds.includes(this.localFilters.speciality_id);
        });
      }
      return this.favourites;
    }
  }
}
</script>

<style scoped>

</style>